import React, { useState } from "react";
import "./EmailValidation.css";

const EmailValidation = ({
  setIsPaymentTermsAndConditionOpen,
  onClose,
  onSubmit,
  email1,
  email2,
  setEmail1,
  setEmail2,
  setIsEmailConfirmed,
}) => {
  const [error, setError] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [showTermsPopup, setShowTermsPopup] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate emails
    if (email1 !== email2) {
      setError(true);
      return;
    }
    // Send back validated email
    if (onSubmit && termsChecked) {
      onSubmit(email1);
    } else {
      // If terms are not checked, show error message
      setError(true);
      return;
    }
    // Perform any other necessary action
    console.log("Emails are valid:", email1);
    setIsEmailConfirmed();
    // Close the popup
    onClose();
  };

  const handleTermsCheckboxChange = (e) => {
    setTermsChecked(e.target.checked);
    setError(false); // Clear any previous error message
  };

  const handleTermsPopup = () => {
    setIsPaymentTermsAndConditionOpen(true);
  };

  return (
    <>
      <div className="double-email-check-validation">
        <h2>הזנ/י את המייל אליו ישלחו ההדמיות.</h2>
        <h4>*יש לאשר את התקנון לפני המעבר לתשולום</h4>
        <form onSubmit={handleSubmit}>
          <div
            className={`double-email-check-validation-form-group ${
              error ? "error" : ""
            }`}
          >
            <label>הקלד/י את הדואר האלקטרוני:</label>
            <input
              type="email"
              value={email1}
              onChange={(e) => setEmail1(e.target.value)}
              required
              placeholder={`דוא"ל`}
            />
          </div>
          <div
            className={`double-email-check-validation-form-group ${
              error ? "error" : ""
            }`}
          >
            <label>הקלדת דואר אלקטרוני בשנתית</label>
            <input
              className="input-email-validation"
              type="email"
              value={email2}
              placeholder={`דוא"ל לאימות`}
              onChange={(e) => setEmail2(e.target.value)}
              required
            />
          </div>
          {error && (
            <p className="double-email-check-validation-error-message">
              {termsChecked
                ? "Emails do not match!"
                : "נא אשר תקנון לפני מעבר לתשלום"}
            </p>
          )}
          <div className="terms-checkbox-container">
            <div
              style={{
                display: "flex",
                alignContent: "center",
                padding: "5px",
              }}
            >
              <input
                type="checkbox"
                id="termsCheckbox"
                checked={termsChecked}
                onChange={handleTermsCheckboxChange}
              />
              <label htmlFor="termsCheckbox">
                קראתי וואני מסכימ/ה עם תנאי התקנון
              </label>
            </div>
            <button
              style={{
                backgroundColor: "transparent",
                border: "0px",
                color: "blue",
                padding: "8px",
                cursor: "pointer",
                fontSize: "15px",
              }}
              type="button"
              onClick={handleTermsPopup}
            >
              לקריאת התקנון לחץ כאן
            </button>
          </div>
          <button
            type="submit"
            className="double-email-check-validation-submit-button"
          >
            מעבר לתשלום
          </button>
        </form>
      </div>
    </>
  );
};

export default EmailValidation;
