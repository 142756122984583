import { Component } from "react";
import { MdPayment } from "react-icons/md";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { MdOutlineMarkEmailRead } from "react-icons/md";

import { MdOutlineEmail } from "react-icons/md";

import { GiClick } from "react-icons/gi";

class ServicesComp extends Component {
  render() {
    function scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Optional: smooth scrolling animation
      });
    }
    return (
      <div className="services__servicesComp">
        <div className="icon-steps-wrapper">
          <div className="two-icone-wrapper">
            <div className="icone-wrapper">
              <div className="icon">
                <span style={{ fontSize: "16px", padding: "5px" }}>{`2`}</span>

                <MdOutlineEmail />
              </div>
              <h4 className="call-for-action-header">
                הכניסו את כתובת הדואר האלקטרוני שלכם.
              </h4>
              <p className="call-for-action-description">
                אנא וודאו כי הכתובת תקינה, לשם תשלח ההדמיה.
              </p>
            </div>
            <div className="icone-wrapper">
              <div className="icon">
                <span style={{ fontSize: "16px", padding: "5px" }}>{`1`}</span>
                <GiClick />
              </div>
              <h4 className="call-for-action-header">
                לחצו על כפתור "העלה תוכנית קומה".
              </h4>
              <p className="call-for-action-description">
                העלו את תוכנית הקומה הרצויה.
              </p>
            </div>
          </div>
          <div className="two-icone-wrapper">
            <div className="icone-wrapper">
              <div className="icon">
                <span style={{ fontSize: "16px", padding: "5px" }}>{`4`}</span>

                <MdOutlineMarkEmailRead />
              </div>
              <h4 className="call-for-action-header">
                המתינו עד קבלת הדואר האלקטרוני עם ההדמיה.
              </h4>
              <p className="call-for-action-description">
                אנו נשלח לכם את ההדמיה בהקדם האפשרי.
              </p>
            </div>
            <div className="icone-wrapper">
              <div className="icon">
                <span style={{ fontSize: "16px", padding: "5px" }}>{`3`}</span>

                <MdPayment />
              </div>
              <h4 className="call-for-action-header">
                לחצו על כפתור "לתשלום" שיופיע לאחר העלאת התוכנית.
              </h4>
              <p className="call-for-action-description">
                השלימו את תהליך התשלום.
              </p>
            </div>
          </div>
        </div>
        <div className="call-for-action-wrapper">
          <p className="call-for-action-phase" style={{ direction: "rtr" }}>
            אז למה אתם מחכים ?<br></br> העלו תוכנית קומה עוד היום בכמה פעולות
            פשוטות:
          </p>
          <a href="#home">
            <button onClick={scrollToTop} className="btn btn-primary">
              לחץ כאן כדי להתחיל!{" "}
            </button>
          </a>
        </div>
      </div>
    );
  }
}

export default ServicesComp;
