import React, { useState, useRef, useEffect } from "react";

import { AiOutlineArrowDown } from "react-icons/ai";
import LivingRoomImage2 from "./imgs/s3Floor3.JPG";
import "./style.css";
import PayPal from "./checkoutPayPal/PayPal";
import _fetch from "isomorphic-fetch";
import axios from "axios";

const conditionText = `תקנון אתר dira3d
הקדמה

אתר dira3d (להלן: "האתר") מאפשר למשתמשים להעלות תוכניות קומה פשוטות לצורך יצירת הדמיות תלת מימדיות (להלן: "השירות"). תוכניות קומה אלו עשויות להיות מוגנות בזכויות יוצרים או בזכויות קניין רוחני אחרות (להלן: "זכויות יוצרים").

שימוש בשירות:

על מנת להשתמש בשירות, המשתמש נדרש להעלות לאתר תוכנית קומה פשוטה בפורמט .pdf או .jpg.
לאחר העלאת תוכנית הקומה, המשתמש יבחר את אפשרויות ההדמיה הרצויות לו, ויבצע תשלום עבור השירות.
לאחר ביצוע התשלום, צוות dira3d ישתמש בתוכנת AI מתקדמת כדי ליצור את ההדמיה התלת מימדית בהתאם לבחירות המשתמש.
ההדמיה התלת מימדית תהיה זמינה בתיבת הדואר של הדואר האלקטרוני אותו הלקוח סיפק בתהליך הרכישה עד 7 ימי עסקים מרגע ביצוע התשלום.

הסרת אחריות:

dira3d מסיר מעליו כל אחריות לפגיעה בזכויות יוצרים עקב השימוש בשירות.
המשתמש שמעלה תוכנית קומה לשירות מצהיר ומאשר כי:
הוא בעל זכויות היוצרים בתוכנית הקומה או שהוא קיבל רשות מהמחזיק בזכויות היוצרים להשתמש בה.
הוא מודע לכך ש-dira3d עשוי לשלוח את תוכנית הקומה לחברות צד שלישי לצורך יצירת ההדמיה התלת מימדית.
הוא נותן ל-dira3d את הרשות המפורשת להשתמש בתוכנית הקומה ולשלוח אותה לחברות צד שלישי לצורך יצירת ההדמיה התלת מימדית.
המשתמש מודע לכך והסכים לכך ש:
dira3d אינו אחראי לבדיקת זכויות היוצרים בתוכניות קומה המועלות לשירות.
dira3d עשוי להסיר מהשירות תוכניות קומה שעלולות להפר זכויות יוצרים.
dira3d עשוי לחסום את גישתו לשירות של משתמשים שמפרים זכויות יוצרים באופן חוזר ונשנה.

הגבלת שימוש:

המשתמש רשאי להשתמש בהדמיות התלת מימדיות שנוצרות באמצעות השירות לשימוש אישי בלבד. אסור למשתמש להשתמש בהן לכל מטרה מסחרית, לרבות פרסום, שיווק או מכירה.
המשתמש רשאי לשתף את ההדמיות התלת מימדיות עם אחרים, אך עליו לציין בבירור שנוצרו באמצעות dira3d.
מידע אישי

dira3d אוסף מידע אישי מהמשתמשים במהלך תהליך הרכישה של ההדמיה, כגון שם, כתובת דואר אלקטרוני ותמונת תוכנית קומה.
מידע זה משמש dira3d לצורך יצירת ההדמיה התלת מימדית ולשליחתה למשתמש.
dira3d לא תשתף את המידע האישי עם צדדים שלישיים ללא הסכמת המשתמש.
המשתמש רשאי לבקש מדיra3d למחוק את המידע האישי שלו בכל עת.

שינויים בתקנון:

dira3d רשאי לשנות את התקנון מעת לעת.
שינויים בתקנון ייכנסו לתוקף עם פרסומם באתר.
המשתמש אחראי לעדכן את עצמו בשינויים בתקנון מעת לעת.
חוק וסמכות שיפוט

התקנון כפוף לחוקי מדינת ישראל וסמכות השיפוט הבלעדית לכל עניין הנוגע לתקנון נתונה לבתי המשפט המוסמכים בישראל.

החזר כספי:

ניתן לקבל החזר כספי מלא עבור שירות dira3d עד 24 שעות ממועד הרכישה.
לאחר 24 שעות ממועד הרכישה,לא ניתן לקבל החזר כספי.
`;

const About = () => {
  const popupRef = useRef(null);

  const [isFileShowPopupOpen, setIsFileShowPopupOpen] = useState(false);
  const [isFileContentPopupOpen, setIsFileContentPopupOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const [totalPaymentSum, setTotalPaymentSum] = useState(0);

  const [selectedFiles, setSelectedFiles] = useState([]);

  const [isImageTermsAndConditionOpen, setIsImageTermsAndConditionOpen] =
    useState(false);

  const [
    isImageTermsAndConditionsApproved,
    setIsImageTermsAndConditionsApproved,
  ] = useState(false);

  const [isPaymentTermsAndConditionOpen, setIsPaymentTermsAndConditionOpen] =
    useState(false);

  const fileInputRef = useRef(null);
  const [isCheckout, setIsCheckout] = useState(false);

  const [email1, setEmail1] = useState("");
  const [email2, setEmail2] = useState("");

  const handleButtonClick = () => {
    setIsImageTermsAndConditionOpen(true);
  };

  useEffect(() => {
    if (!isImageTermsAndConditionOpen && isImageTermsAndConditionsApproved) {
      fileInputRef.current.click();
    }
  }, [isImageTermsAndConditionOpen, isImageTermsAndConditionsApproved]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://dira3d.onrender.com");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleFileChange = async (event) => {
    const files = event.target.files;
    const filesArray = Array.from(files);

    if (selectedFiles.length > 0) {
      const tempSelectedFiles = [...selectedFiles, ...filesArray];
      setTotalPaymentSum(tempSelectedFiles.length * 699);
      setSelectedFiles(tempSelectedFiles);
    } else {
      setTotalPaymentSum(filesArray.length * 699);
      setSelectedFiles(filesArray);
    }
  };

  const createOrder = async () => {
    try {
      const response = await axios.post(
        "https://dira3d.onrender.com/api/orders",
        // "/api/orders",

        {
          cart: [{ id: "YOUR_PRODUCT_ID", quantity: selectedFiles.length }],
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      const orderData = response.data;

      if (!orderData.id) {
        const errorDetail = orderData.details[0];
        const errorMessage = errorDetail
          ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
          : "Unexpected error occurred, please try again.";

        throw new Error(errorMessage);
      }

      return orderData.id;
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error("Error response:", error.response.data);
      } else if (error.request) {
        // Request was made but no response was received
        console.error("Error request:", error.request);
      } else {
        // Something else happened in setting up the request
        console.error("Error message:", error.message);
      }
    }
  };

  async function onApprove(data, actions) {
    try {
      if (selectedFiles.length === 0) {
        console.error("No files selected");
        return;
      }

      const modifiedFilesArray = selectedFiles.map((file, index) => {
        const modifiedFileName = `${index} - ${email1}`; // Modify the file name here
        return new File([file], modifiedFileName, { type: file.type });
      });

      const formData = new FormData();
      modifiedFilesArray.forEach((file, index) => {
        formData.append(`file`, file);
      });

      const response = await axios.post(
        `https://dira3d.onrender.com/api/orders/${data.orderID}/capture`,
        // `/api/orders/${data.orderID}/capture`,

        formData
      );

      const orderData = response.data;

      const errorDetail = orderData?.details?.[0];

      if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
        // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
        // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
        return actions.restart();
      } else if (errorDetail) {
        // (2) Other non-recoverable errors -> Show a failure message
        throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
      } else if (!orderData.purchase_units) {
        throw new Error(JSON.stringify(orderData));
      } else {
        // (3) Successful transaction -> Show confirmation or thank you message
        // Or go to another URL:  actions.redirect('thank_you.html');
        const transaction =
          orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
          orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];

        const container = document.querySelector("#result-message");
        container.innerHTML = `ההזמנה בוצעה בהצלחה ! <br><br> אנא בדוק את תיבת הדואר הנכנס בכתוב דואר הרלקטרוני שלך`;

        console.log(
          "Capture result",
          orderData,
          JSON.stringify(orderData, null, 2)
        );
      }
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error("Error response:", error.response.data);
      } else if (error.request) {
        // Request was made but no response was received
        console.error("Error request:", error.request);
      } else {
        // Something else happened in setting up the request
        console.error("Error message:", error.message);
      }
      const container = document.querySelector("#result-message");
      container.innerHTML = `Sorry, your transaction could not be processed...<br><br>${error.message}`;
    }
  }

  const onError = (err) => {
    console.log("🚀 ~ onError ~ err:", err);
    // For example, redirect to a specific error page
  };

  const handleFileClick = (file) => {
    setSelectedFile(file);
    setIsFileContentPopupOpen(true);
  };

  const handleDeleteFile = (fileToDelete) => {
    const tempFileArray = selectedFiles.filter((file) => file !== fileToDelete);
    if (tempFileArray) {
      setSelectedFiles(tempFileArray);
    } else {
      setSelectedFiles([]);
    }
  };
  return (
    <section className="about__container">
      {isImageTermsAndConditionOpen && (
        <Popup
          onClose={() => {
            setIsImageTermsAndConditionOpen(false);
          }}
          textContent={conditionText}
          onConfirm={() => {
            setIsImageTermsAndConditionsApproved(true);
          }}
        ></Popup>
      )}
      {isCheckout && !isPaymentTermsAndConditionOpen && (
        <div className="paypal-overlay-screen">
          <div className="paypal-popup-container" ref={popupRef}>
            <div className="paypal-popup-header">
              <button
                className="paypal-popup-close-button"
                onClick={() => setIsCheckout(false)}
              >
                X
              </button>
            </div>
            <div className="paypal-popup-content">
              <PayPal
                setIsPaymentTermsAndConditionOpen={
                  setIsPaymentTermsAndConditionOpen
                }
                email1={email1}
                setEmail1={setEmail1}
                email2={email2}
                setEmail2={setEmail2}
                onError={onError}
                onApprove={onApprove}
                createOrder={createOrder}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
              />
            </div>
          </div>
        </div>
      )}
      {isPaymentTermsAndConditionOpen && (
        <Popup
          onClose={() => {
            setIsPaymentTermsAndConditionOpen(false);
          }}
          textContent={conditionText}
          onConfirm={() => {}}
          isPaymentTermsAndConditions={true}
        ></Popup>
      )}

      <div className="container">
        <div className="text-wrapper">
          <h1 className="title-header">מנגישים את עתיד הנדל"ן</h1>
          <h1 className="title-header">הפיכת הדמיות AI למציאות ישראלית</h1>

          <p className="text">
            עולם הנדל"ן עובר מהפכה טכנולוגית, ואנחנו כאן כדי להנגיש אותה לכל
            ישראלי. אנו משתמשים בטכנולוגיות בינה מלאכותית מתקדמות כדי ליצור
            הדמיות תלת מימדיות מרהיבות של נכסים, תוך שימוש בתוכנית קומה פשוטה.
            אנו מאמינים שכל אחד ראוי לחוות את פוטנציאל הנדל"ן בצורה חדשנית
            ומרתקת.
          </p>
          <div className="about__buttons">
            {selectedFiles.length > 0 && (
              <button
                className="checkout-button"
                onClick={() => {
                  // setIsEmailValidationPopup(true);
                  setIsCheckout(true);
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ direction: "rtl" }}> מעבר לתשלום</span>
                  <span
                    style={{ direction: "rtl" }}
                  >{`(${totalPaymentSum} ש"ח) `}</span>
                </div>
              </button>
            )}

            <button
              className="btn btn-primary"
              onClick={() => {
                if (!isImageTermsAndConditionsApproved) {
                  handleButtonClick();
                } else {
                  fileInputRef.current.click();
                }
              }}
            >
              העלה תוכנית קומה
            </button>
            <input
              ref={fileInputRef}
              type="file"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />

            <a href="#portfolio">
              <span className="our-project-link" style={{ direction: "rtl" }}>
                ראו הדמיות
              </span>
              <AiOutlineArrowDown className="bouncing-arrow" />
            </a>
          </div>
          {selectedFiles.length > 0 && (
            <span
              className="show-files-uploaded"
              onClick={() => setIsFileShowPopupOpen(true)}
            >
              {`קבצים שהועלו (${selectedFiles.length})`}
            </span>
          )}
        </div>
        <div style={{ position: "relative" }}>
          <div className="about__image">
            <img
              className="livingroom-img"
              src={LivingRoomImage2}
              alt="about"
            />
          </div>
          <div className="about__progress">
            {/* <div className="progress_bar">
              <CircularProgressbar
                value={percentage}
                text={`${percentage}%`}
              />
            </div> */}
            <h4 className="popup-with-price">
              <p>
                הדמיית קומת דירה - החל מ: <br />
                ₪699 בלבד
              </p>
            </h4>
          </div>
        </div>
      </div>

      {isFileShowPopupOpen && (
        <FileShowPopup
          files={selectedFiles}
          onClose={() => setIsFileShowPopupOpen(false)}
          onFileClick={handleFileClick}
          onDeleteFile={handleDeleteFile}
        />
      )}
      {isFileContentPopupOpen && selectedFile && (
        <FileContentPopup
          file={selectedFile}
          onClose={() => setIsFileContentPopupOpen(false)}
        />
      )}
    </section>
  );
};

const FileShowPopup = ({ files, onClose, onFileClick, onDeleteFile }) => {
  return (
    <div className="file-show-popup-overlay" onClick={onClose}>
      <div className="file-show-popup" onClick={(e) => e.stopPropagation()}>
        <button className="files-close-button" onClick={onClose}>
          X
        </button>
        <h2
          className="delete-file-popup-header-delete-show"
          style={{ direction: "rtl", paddingBottom: "18px" }}
        >
          קבצים שהועלו
        </h2>
        <ul>
          {files.map((file, index) => (
            <li key={index}>
              <span onClick={() => onFileClick(file)}>{file.name}</span>
              <button
                className="file-delete-button-delete-file"
                onClick={() => onDeleteFile(file)}
              >
                Delete
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const FileContentPopup = ({ file, onClose }) => {
  const isImage = file.type.startsWith("image/");

  return (
    <div className="file-content-popup-overlay" onClick={onClose}>
      <div className="file-content-popup" onClick={(e) => e.stopPropagation()}>
        <button className="files-close-button" onClick={onClose}>
          X
        </button>
        {isImage ? (
          <img src={URL.createObjectURL(file)} alt={file.name} />
        ) : (
          <p>This file is not an image</p>
        )}
      </div>
    </div>
  );
};

const Popup = ({
  textContent,
  onClose,
  onConfirm,
  isPaymentTermsAndConditions = false,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      <div className="terms-and-condition-backdrop" onClick={onClose}></div>
      <div className="terms-and-condition-popup">
        <div className="terms-and-condition-popup-content">
          <div className="terms-and-condition-popup-header">
            <h2> אשר/י תקנון </h2>
          </div>
          <div className="terms-and-condition-popup-body">
            <div className="terms-and-condition-scrollable-content">
              <p>{textContent}</p>
            </div>
            <div className="terms-and-condition-checkbox-container">
              <label className="terms-and-condition-check-box">
                <input
                  className="terms-and-condition-check-box-to-check"
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                קראתי ואישרתי את התוכן
              </label>
              <span>* לחץ על "קראתי ואישרתי את התוכן" כדי לאשר את התקנון</span>
            </div>
          </div>
          <div className="terms-and-condition-popup-footer">
            <button
              className="terms-and-condition-cancel-button"
              onClick={onClose}
            >
              {isPaymentTermsAndConditions ? "סגור" : "ביטול"}
            </button>
            {!isPaymentTermsAndConditions && (
              <button
                onClick={() => {
                  onConfirm();
                  onClose();
                }}
                className={
                  isChecked
                    ? "terms-and-condition-confirm-button"
                    : "terms-and-condition-confirm-button-disabled"
                }
                disabled={!isChecked}
              >
                אישור
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default About;
