import React, { useState, useRef, useEffect } from "react";
import EmailValidation from "./EmailValidation";

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const PayPal = ({
  setIsPaymentTermsAndConditionOpen,
  email1,
  setEmail1,
  email2,
  setEmail2,
  onError,
  onApprove,
  createOrder,
  selectedFiles,
  setSelectedFiles,
}) => {
  const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);

  const initialOptions = {
    clientId:
      "AVFt3sfYLE3uL5GZ77uWhu1U-nJ5HYkiYm0K_ER9zb7HFxDgLbDJ4vBSAZmlZYWR6DQASoib1W9y2B4v",
    currency: "ILS",
    locale: "he_IL", // Add other options as needed
  };

  useEffect(() => {
    // Modify file names
  }, [isEmailConfirmed]);
  return (
    <div className="paypal-wrapper">
      <p id="result-message"></p>
      {isEmailConfirmed ? (
        // <div ref={paypal}></div>
        <PayPalScriptProvider options={initialOptions}>
          <PayPalButtons
            createOrder={createOrder}
            onApprove={onApprove}
            onError={onError}
          />
        </PayPalScriptProvider>
      ) : (
        <EmailValidation
          setIsPaymentTermsAndConditionOpen={setIsPaymentTermsAndConditionOpen}
          onClose={() => {}}
          onSubmit={() => {}}
          email1={email1}
          email2={email2}
          setEmail1={setEmail1}
          setEmail2={setEmail2}
          setIsEmailConfirmed={() => {
            setIsEmailConfirmed(true);
          }}
        ></EmailValidation>
      )}
    </div>
  );
};

export default PayPal;
