import { Component } from "react";
import { BiBrain } from "react-icons/bi";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { TiHomeOutline } from "react-icons/ti";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import Bathroom from "./imgs/3dFloor2.JPG";

class Intro extends Component {
  render() {
    return (
      <div className="services__intro">
        <div className="pos-rel">
          <div className="services__intro__image">
            <img src={Bathroom} alt="" />
          </div>
          <Swiper
            className="services__intro__miniSlider"
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
          >
            <SwiperSlide>
              <div className="icon">
                <TiHomeOutline />
              </div>
              <p className="swiper-inner-text" style={{ direction: "rtl" }}>
                <b>
                  מציאות וירטואלית חדשה, בינה מלאכותית מציירת את עתיד ההדמיות
                </b>
              </p>
            </SwiperSlide>
            <SwiperSlide>
              <div className="icon">
                <TiHomeOutline />
              </div>
              <p className="swiper-inner-text" style={{ direction: "rtl" }}>
                <b>
                  מציאות וירטואלית חדשה, בינה מלאכותית מציירת את עתיד ההדמיות
                </b>
              </p>
            </SwiperSlide>
          </Swiper>
        </div>
        <div>
          <div className="services__intro__text">
            <h4 className="our-benefits-title">איך זה עובד?</h4>
            <h2 className="our-benefits-title-under-title">
              קבלו הדמיה ויזואלית מרהיבה בשלושה צעדים פשוטים:{" "}
            </h2>
            <p className="text">
              <b>1. העלו תוכנית קומה: </b> <br></br>
              הכל מתחיל בתוכנית קומה פשוטה של דירתכם. תוכלו לסרוק אותה ולהעלות
              אותה בקובץ.
            </p>
            <p className="text">
              <b> 2. שירותי AI מתקדמים יעבדו עבורכם: </b>
              <br></br>
              צוות טכנולוגיית בינה מלאכותית (AI) מיומן יקבל את תוכנית הקומה שלכם
              וידאג לכל השאר:<br></br>
              <br></br>
              -הלבשת הבית ברהיטים וסניטריה.<br></br>
              -בחירת חיפויים, צבעים וטקסטורות.<br></br>
              -יצירת הדמיה ויזואלית מרהיבה.<br></br>
            </p>
            <p className="text">
              <b> 3.קבלו את ההדמיה עד 7 ימי עסקים: </b>
              <br></br>
              תוך זמן קצר יחסית, תקבלו בדואר האלקטרוני את ההדמיה הוויזואלית
              המרהיבה. תוכלו לצפות בה ולשתף אותה עם חברים ובני משפחה, לקבל השראה
              לעיצוב הבית החדש שלכם.
            </p>

            <p className="text-important-to-mention">
              **חשוב לציין:ההדמיה הוויזואלית היא תיאור ויזואלי תיאורטי של
              דירתכם, תוך שימוש בתוכנית הקומה שסיפקתם. ייתכנו פערים בין ההדמיה
              הוויזואלית לבין המציאות, כגון מידות לא מדויקות במאת האחוזים, מיקום
              לא מדויק של אלמנטים (כמו חלונות, קירות וכו'), קריאה לקויה של ה-AI
              אל מול התוכנית או גורמים אחרים. ההדמיה הוויזואלית אינה מהווה תחליף
              לחוות דעת מקצועית של אדריכל או מעצב פנים. אנו ממליצים לכם להתייעץ
              עם איש מקצוע מוסמך לפני קבלת החלטות סופיות לגבי עיצוב דירתכם.
              ההדמיה אינה ברת שינוי. התוצר הסופי שתקבלו בדוא"ל הוא תוצר סופי
              בהחלט, גם אם הוא אינו מתאים במאת האחוזים לדירתכם. לכן, אין החזר
              כספי בגין סיבות אלו. המטרה של ההדמיה היא לספק לכם חזון עיצובי
              וסטיילינג לביתכם על בסיס תוכנית הקומה שסיפקתם.
            </p>
            {/* <button className="btn btn-primary">More About</button> */}
          </div>
        </div>
      </div>
    );
  }
}
export default Intro;
