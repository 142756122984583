import { Component } from "react";
import Header from "./components/Header/Header";
import Nav from "./components/Nav/Nav";
import About from "./components/About/About";
import Services from "./components/Services/Services";
import Portfolio from "./components/Portfolio/Portfolio";
import Testimonials from "./components/Testimonials/Testimonials";
import News from "./components/News/News";
import Banner from "./components/Banner/Banner";
import Footer from "./components/Footer/Footer";
import Faq from "./components/FAQ/Faq";
import PaymentProgress from "./components/PaymentProgress/PaymentProgress";
import "./App.css";

class App extends Component {
  render() {
    return (
      <div className="App">
        {/* <PaymentProgress></PaymentProgress> */}
        <Header />
        <Nav />
        <About />
        <Services />
        <Portfolio />
        {/* <Testimonials /> */}
        {/* <News /> */}
        <Faq></Faq>
        {/* <Banner /> */}
        <Footer />
      </div>
    );
  }
}

export default App;
