import React, { useState } from "react";
import "./FAQ.css"; // Import your CSS file for styling
import { CiCirclePlus } from "react-icons/ci";
import { CiCircleMinus } from "react-icons/ci";

const Faq = () => {
  const [expandedItem, setExpandedItem] = useState(null);

  const toggleAccordion = (index) => {
    if (expandedItem === index) {
      setExpandedItem(null);
    } else {
      setExpandedItem(index);
    }
  };

  // Dummy data for accordion items
  const accordionData = [
    {
      question: "איזה סוג של קובץ עליי להעלות?",
      answer:
        "אתה יכול להעלות קובץ JPG, PNG או PDF. הקובץ צריך לכלול תוכנית קומה של דירה אחת או בית אחד, כדי שה-AI יוכל להבין על מה עליו לעבוד להדמיה.",
    },
    {
      question: `האם ניתן לשנות את עיצוב הפנים, הריהוט, צבע הקירות וכו'?`,
      answer: "לא ניתן לשנות את עיצוב הפנים, הריהוט, צבע הקירות וכו'",
    },
  ];

  return (
    <div className="faq-wrapper">
      <div className="faq-container">
        <h2 className="faq-title">שאלות ותשובות</h2>
        <div className="faq-accordion">
          {accordionData.map((item, index) => (
            <div className="faq-accordion-item" key={index}>
              <button
                id={`faq-accordion-button-${index + 1}`}
                aria-expanded={expandedItem === index ? "true" : "false"}
                onClick={() => toggleAccordion(index)}
              >
                {index === expandedItem ? (
                  <CiCircleMinus />
                ) : (
                  <CiCirclePlus></CiCirclePlus>
                )}
                <span className="faq-accordion-title">{item.question}</span>
              </button>
              <div
                className={`faq-accordion-content ${
                  expandedItem === index ? "faq-expanded" : ""
                }`}
              >
                <p>{item.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
