import React from "react";
import "./style__work.css";
import filledFloorPlan from "./imgs/filledFloorPlan.JPG";
import emptyFloorPlan from "./imgs/emptyFloorPlan.JPG";

const Work = (props) => {
  return (
    <div className="portfolio__work" onClick={props.handleOnClick}>
      <img
        style={{
          objectFit: "contain",
          // transform: "rotate(90deg)",
          width: "100%",
        }}
        src={props.img}
        alt=""
      />
      {/* <img
        style={{
          objectFit: "contain",
          transform: "rotate(90deg)",
          width: "100%",
        }}
        src={props.img}
        alt=""
      /> */}
      <div className="header-and-text-wrapper-work">
        <h2 className="link-header">{props.title}</h2>
        <p className="text">{props.text}</p>
      </div>
    </div>
  );
};

export default Work;
