import { useEffect, useState } from "react";
import React from "react";
import Work from "./Work_box";
import "./style.css";
import emptyFloorPlan1 from "./imgs/architect_1_pre_3d.png";

const Portfolio = () => {
  const [isIframeOpen, setIsIframeOpen] = useState(false);
  const works = [
    {
      img: emptyFloorPlan1,
      title: "לחצו כאן לכניסה להדמיה",
      text: ".הדמיה שיצרנו לתוכנית דירה זמינה לצפייה בלחיצה כאן.",
    },
    {
      img: emptyFloorPlan1,
      title: "לחצו כאן לכניסה להדמיה",
      text: ".הדמיה שיצרנו לתוכנית דירה זמינה לצפייה בלחיצה כאן.",
    },
    {
      img: emptyFloorPlan1,
      title: "לחצו כאן לכניסה להדמיה",
      text: ".הדמיה שיצרנו לתוכנית דירה זמינה לצפייה בלחיצה כאן.",
    },
  ];

  const handleClick = () => {
    setIsIframeOpen(true);
  };

  return (
    <>
      {isIframeOpen ? (
        <Iframe
          src="https://dira3d.widget.floor-plan.online/widget?id=043ffc3d-bbcb-457c-8abf-5c80d960b68f"
          onClose={() => {
            setIsIframeOpen(false);
          }}
        ></Iframe>
      ) : (
        <section className="container container__portfolio" id="portfolio">
          {works.map((work, index) => {
            if (index === 1) {
              return (
                <div key={`our-work-${index}`}>
                  <div className="portfolio__header">
                    <h1 className="works-for-example-title">
                      בדקו את ההדמיות שלנו
                    </h1>
                    {/* <button className="btn btn-primary">More About</button> */}
                  </div>
                  <Work
                    handleOnClick={() => {
                      handleClick();
                    }}
                    img={work.img}
                    title={work.title}
                    text={work.text}
                  />
                </div>
              );
            }
            return (
              <Work
                handleOnClick={() => {
                  handleClick();
                }}
                key={`work-else-${index}`}
                img={work.img}
                title={work.title}
                text={work.text}
              />
            );
          })}
        </section>
      )}
    </>
  );
};

const Iframe = ({ src, onClose }) => {
  return (
    <div className="iframe-overlay">
      <div className="iframe-container">
        <div
          className="close-button"
          onClick={onClose}
          style={{ zIndex: "9999" }}
        >
          X
        </div>
        <iframe className="iframe-content" src={src} title="Embedded Content" />
      </div>
    </div>
  );
};

export default Portfolio;
