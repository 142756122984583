import { Component } from "react";
import { CgInstagram } from "react-icons/cg";
import { AiFillTwitterSquare } from "react-icons/ai";
import { AiFillFacebook } from "react-icons/ai";
import "./style.css";
class Footer extends Component {
  render() {
    return (
      <footer id="contact" className="container footer__container">
        <div
          className="about"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <h3>dira3D</h3>
          <p className="text">
            אנו חברת טכנולוגיה חדשנית המציעה שירותי הדמיה ויזואלית מרהיבים של
            דירות. בעזרת טכנולוגיית בינה מלאכותית מתקדמת, אנו מאפשרים לכם לקבל
            חזון עיצובי של ביתכם
          </p>
          <ul className="footer__socials" style={{ display: "flex" }}>
            <li>
              <a href="#">
                <AiFillFacebook />
              </a>
            </li>
            <li>
              <a href="#">
                <CgInstagram />
              </a>
            </li>
            <li>
              <a href="#">
                <AiFillTwitterSquare />
              </a>
            </li>
          </ul>
        </div>
        <div className="services">
          <h3>שירותים</h3>
          <ul>
            <li>
              <a className="text">הדמיות</a>
            </li>
            {/* <li>
              <a href="#" className="text">
                IT COnsultant
              </a>
            </li>
            <li>
              <a href="#" className="text">
                Strategy & Research
              </a>
            </li> */}
          </ul>
        </div>
        <div className="contact">
          <h3>צור קשר</h3>
          <ul>
            {/* <li>
              <address className="text">כתובת:לבנתיים אין</address>
            </li>
            <li>
              <a href="tel:+212698584458" className="text">
                טלפון : 050-050-050
              </a>
            </li> */}
            <li>
              <a href="mailto:support@dira3d.co.il" className="text">
                אימייל : support@dira3d.co.il
              </a>
            </li>
          </ul>
        </div>
      </footer>
    );
  }
}

export default Footer;
