import React, { useState, useEffect } from "react";
import { RiMenu3Line } from "react-icons/ri";

const Links = () => {
  const links = [
    "contact",
    // "news",
    // "testimony",
    "portfolio",
    "services",
    // "about",
    "home",
  ];

  const hebrewTranslator = {
    contact: "צור קשר",
    news: "חדשות",
    testimony: "חוות דעת",
    portfolio: " הדמיות לדוגמה",
    services: "שרותים",
    about: "אודות",
    home: "בית",
  };

  const [linksVisibility, setLinksVisibility] = useState(false);
  const [currentTab, setCurrentTab] = useState("home");

  const toggleVisibility = () => {
    setLinksVisibility(!linksVisibility);
  };

  useEffect(() => {
    // if (!linksVisibility) {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setCurrentTab("home");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // }
  }, []); // Run this effect only once on component mount

  return (
    <>
      <div className="links__button hide__button">
        <button className="btn menu" onClick={toggleVisibility}>
          <RiMenu3Line />
        </button>
      </div>
      <div className={`links ${!linksVisibility ? "hide__links" : ""}`}>
        {links.map((link, index) => (
          <a
            key={`link-${index}`}
            className={currentTab === link ? "active" : "not-active"}
            href={`#${link}`}
            onClick={() => {
              toggleVisibility();
              setCurrentTab(link);
            }}
          >
            {hebrewTranslator[link]}
          </a>
        ))}
      </div>
    </>
  );
};

export default Links;
